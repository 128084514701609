import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { TCustomerPlatformAndDadataOrganization } from 'src/models/customer';
import { baseQueryWithReauth } from 'src/store/utils';
import {
  ICommonCompany,
  ICompany,
  IDeliveryAddress,
  ICustomerList,
  TCustomerOrganization,
} from 'src/models';
import { TListRequestParams } from 'src/api/models';
import {
  IOrganizationBankDetails,
  IOrganizationDetails,
} from 'src/models/company';
import { businessRequestApi } from 'src/api/business-request/business-request';

export type TSortKey = 'CREATED_AT' | 'BUSINESS_APPLICATIONS_COUNT';
export type TCustomerFilters = {
  isAssigned: boolean;
  customerType?: string;
};
export type TCustomerListParams = TListRequestParams<
  TSortKey,
  TCustomerFilters
>;

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Customer'],
  endpoints: (builder) => ({
    getById: builder.query<ICompany, number>({
      providesTags: ['Customer'],
      query: (id) => ({
        url: `organizations/api/v2/organization/${id}/customer`,
      }),
    }),
    addCustomer: builder.mutation<
      { id: number },
      {
        name: string;
        inn?: string;
      }
    >({
      query: (body) => ({
        url: `/organizations/api/v1/organization/simple/customer`,
        method: 'POST',
        body,
      }),
    }),
    getList: builder.mutation<ICustomerList, TCustomerListParams>({
      query: (body) => ({
        url: `/organizations/api/v1/organization/customer`,
        method: 'POST',
        body,
      }),
    }),
    update: builder.mutation<ICompany, ICommonCompany>({
      query: ({ id, ...body }) => ({
        url: `organizations/api/v2/organization/${id}/customer`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            customerApi.util.updateQueryData('getById', id, () => resultData)
          );
        } catch {
          // ignore
        }
      },
    }),
    getDeliveryAddress: builder.query<IDeliveryAddress[], number>({
      query: (companyId) => ({
        url: `organizations/api/v1/organization/${companyId}/customer/delivery`,
      }),
      transformResponse: (response: Array<IDeliveryAddress>) =>
        response.sort((a) => (a.type === 'PLAIN' ? 1 : -1)),
    }),
    createDeliveryAddress: builder.mutation<
      IDeliveryAddress[],
      { companyId: number; body: Omit<IDeliveryAddress, 'id' | 'comment'> }
    >({
      query: ({ companyId, body }) => ({
        url: `organizations/api/v1/organization/${companyId}/customer/delivery`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            customerApi.util.updateQueryData(
              'getDeliveryAddress',
              companyId,
              () => [...resultData].sort((a) => (a.type === 'PLAIN' ? 1 : -1))
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),
    updateDeliveryAddress: builder.mutation<
      IDeliveryAddress[],
      { companyId: number; body: IDeliveryAddress }
    >({
      query: ({ companyId, body }) => ({
        url: `organizations/api/v1/organization/${companyId}/customer/delivery/${body.id}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            customerApi.util.updateQueryData(
              'getDeliveryAddress',
              companyId,
              () => [...resultData].sort((a) => (a.type === 'PLAIN' ? 1 : -1))
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),
    deleteDeliveryAddress: builder.mutation<
      void,
      { companyId: number; addressId: number }
    >({
      query: ({ companyId, addressId }) => ({
        url: `/organizations/api/v1/organization/${companyId}/customer/delivery/${addressId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { companyId, addressId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            customerApi.util.updateQueryData(
              'getDeliveryAddress',
              companyId,
              (draft) => draft.filter((item) => item.id !== addressId)
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),
    getCustomerOrganizations: builder.mutation<
      Array<TCustomerOrganization>,
      Array<number>
    >({
      query: (body) => ({
        url: 'organizations/api/v1/customer',
        method: 'POST',
        body: {
          ids: body,
        },
      }),
    }),

    updateCompanyDetails: builder.mutation<
      IOrganizationDetails,
      Pick<ICommonCompany, 'id' | 'organizationDetails' | 'inn'>
    >({
      invalidatesTags: ['Customer'],
      query: ({ id, ...body }) => ({
        url: `organizations/api/v2/organization/${id}/customer/details`,
        method: 'PUT',
        body: { inn: body.inn, organizationDetails: body.organizationDetails },
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        // TODO: Проверить необходимость try-catch в этом месте
        try {
          const { data: resultData } = await queryFulfilled;

          dispatch(
            customerApi.util.updateQueryData('getById', id, (data) => ({
              ...data,
              ...resultData,
            }))
          );

          // TODO: Подумать, можно ли сбросить заказчиков, не зная businessRequestId?
          // https://tracker.yandex.ru/DEVINHOUSE-994
          dispatch(businessRequestApi.util?.resetApiState());
        } catch {
          throw new Error();
        }
      },
    }),

    updateBankDetails: builder.mutation<
      IOrganizationBankDetails,
      Pick<ICommonCompany, 'id' | 'organizationBankDetails'>
    >({
      query: ({ id, ...body }) => ({
        url: `organizations/api/v2/organization/${id}/customer/bank-details`,
        method: 'PUT',
        body: body.organizationBankDetails,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            customerApi.util.updateQueryData('getById', id, (data) => ({
              ...data,
              organizationBankDetails: {
                ...resultData,
              },
            }))
          );
        } catch {
          throw new Error();
        }
      },
    }),

    getCustomerOrganizationsDaData: builder.query<
      Array<TCustomerPlatformAndDadataOrganization>,
      { searchQuery: string }
    >({
      query: (params) => ({
        url: 'organizations/api/v1/customer/search/by-inn-and-name-with-dadata',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetByIdQuery,
  useLazyGetByIdQuery,
  useGetDeliveryAddressQuery,
  useLazyGetDeliveryAddressQuery,
  useCreateDeliveryAddressMutation,
  useUpdateDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
  useGetListMutation,
  useAddCustomerMutation,
  useGetCustomerOrganizationsMutation,
  useUpdateCompanyDetailsMutation,
  useUpdateBankDetailsMutation,
  useLazyGetCustomerOrganizationsDaDataQuery,
} = customerApi;
