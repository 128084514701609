import { FC, useState } from 'react';
import { Card } from 'src/components/ui';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { OpenChat } from 'src/components/widgets';
import { CompanyTypes, ConversationEntityType, ICompany } from 'src/models';
import { AuthService } from 'src/services';
import { ROLES } from 'src/constants';
import { useMatch } from 'react-router-dom';
import { ContactCard } from 'src/components/widgets/contact-card';
import styles from './manager-card.module.scss';
import { ChangeManagerDialog } from '../change-manager-dialog/change-manager-dialog';

export type TCardManager = {
  companyData?: ICompany;
  companyType?: CompanyTypes;
  changeManagerAllowed?: boolean;
  onRefetch?: () => void;
  manager?: {
    id: number;
    login: string;
    lastName: string;
    firstName: string;
    middleName: string;
    position: string;
    contactInfoDto: {
      phones: TPhoneInputValue[];
    };
  };
  showButton?: boolean;
  children?: React.ReactNode;
};

// TODO: Нельзя менять ответственного менеджера? Только для админа?
// TODO: Где авторизация под пользователем? Только для админа?

export const ManagerCard: FC<TCardManager> = ({
  manager,
  companyData,
  companyType,
  showButton = false,
  changeManagerAllowed,
  onRefetch,
  children,
}) => {
  console.log('Дети ', children);
  const [open, setOpen] = useState(false);
  const isCustomer = AuthService.currentRole === ROLES.CUSTOMER;
  const matches = useMatch('/companies/:id/*');
  const entityPath = matches?.params['*'] || '';

  const entityVariants: Record<string, ConversationEntityType> = {
    contract: ConversationEntityType.CONTRACTOR,
    about: isCustomer
      ? ConversationEntityType.ORGANIZATION_ABOUT_CUSTOMER
      : ConversationEntityType.ORGANIZATION_ABOUT_CONTRACTOR,
    'about/contacts': isCustomer
      ? ConversationEntityType.ORGANIZATION_CONTACTS_CUSTOMER
      : ConversationEntityType.ORGANIZATION_CONTACTS_CONTRACTOR,
    requisites: isCustomer
      ? ConversationEntityType.ORGANIZATION_DETAILS_CUSTOMER
      : ConversationEntityType.ORGANIZATION_DETAILS_CONTRACTOR,
    'requisites/bank': isCustomer
      ? ConversationEntityType.ORGANIZATION_BANK_DETAILS_CUSTOMER
      : ConversationEntityType.ORGANIZATION_BANK_DETAILS_CONTRACTOR,
    'requisites/shipping': ConversationEntityType.ORGANIZATION_SHIPPING_DETAILS,
    'requisites/delivery': ConversationEntityType.ORGANIZATION_DELIVERY_DETAILS,
    'tech-opportunities':
      ConversationEntityType.ORGANIZATION_TECHNICAL_OPPORTUNITY,
  };
  const entityType = entityVariants[entityPath] ?? 'CONTRACT';

  // test
  return manager ? (
    <Card className={styles.card} noMargin noContentPadding>
      {children}
      <ContactCard
        item={manager}
        onChangeContact={
          changeManagerAllowed ? () => setOpen(true) : undefined
        }>
        {showButton && companyData ? (
          <OpenChat
            entityType={entityType}
            entityId={companyData.id}
            mode="contragent"
            buttonText=""
            className={styles.emptyText}
          />
        ) : undefined}
      </ContactCard>
      {changeManagerAllowed && (
        <ChangeManagerDialog
          open={open}
          onClose={() => setOpen(false)}
          onRefetch={onRefetch}
          companyData={companyData!}
          companyType={companyType!}
        />
      )}
    </Card>
  ) : null;
};

// <Card className={styles.card} noMargin noContentPadding>
//   <div className={styles.cardHeader}>
//     <Treaty className={styles.cardHeaderIcon} />
//     <Typography color="secondary.light">
//       {manager.position || 'Ответственный менеджер'}
//     </Typography>
//     <Typography className={styles.cardHeaderManager}>
//       {
//         joinName(manager.lastName, manager.firstName, manager.middleName)
//           .fullName
//       }
//     </Typography>
//   </div>
//   <Divider />
//   <div className={styles.cardMain}>
//     {manager.contactInfoDto.phones.map((element) => (
//       <InfoRow
//         key={element.number}
//         className={styles.cardMainItem}
//         label={PHONES[element.type]}
//         value={<PhoneInput displayType="text" value={element} />}
//       />
//     ))}
//     <InfoRow
//       className={styles.cardMainItem}
//       label="Электронный адрес"
//       value={<Link href={`mailto:${manager.login}`}>{manager.login}</Link>}
//     />
//     {showButton && companyData && (
//       <OpenChat
//         entityType={entityType}
//         entityId={companyData.id}
//         mode="contragent"
//         sx={{ marginTop: '16px' }}
//         fullWidth
//       />
//     )}
//   </div>
// </Card>
