import { FC } from 'react';
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format';
import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from 'src/components/ui';

type IAdditionalNumberProps = Omit<
  NumberFormatBaseProps,
  'format' | 'removeFormatting' | 'getCaretBoundary'
> &
  OutlinedInputProps;

export const AdditionalNumber: FC<IAdditionalNumberProps> = ({
  displayType,
  ...props
}) => {
  const format = (words: string) => words;

  const removeFormatting = (word: string) =>
    /^#?\d{0,7}$/.exec(word) ? word : word.slice(0, -1);

  const getCaretBoundary = () => [false];

  return (
    <NumberFormatBase
      {...(displayType === 'text' ? props : { ...props, trimValue: false })}
      displayType={displayType}
      customInput={OutlinedInput}
      format={format}
      removeFormatting={removeFormatting}
      getCaretBoundary={getCaretBoundary}
      startAdornment={
        <InputAdornment
          sx={{
            fontSize: '14px',
            color: '#A8A8A8',
            marginTop: '2px',
            marginRight: '22px',
          }}
          position="start"
          disableTypography>
          Доб.
        </InputAdornment>
      }
    />
  );
};
