import { FC, ReactNode } from 'react';

export enum DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum FilterTypes {
  SEARCH = 'search',
  SORT = 'sort',
  FILTERS = 'filters',
  CUSTOM_TAG = 'customTag',
}

export type TChip = {
  type: FilterTypes;
  // Используется только при редактировании значений Чипов типа SEARCH
  onSearchChipEdit?: (oldValue: string, newValue: string) => void;
  updateEditChipRefValue?: (newValue: string | null) => void;
  editChipRefValue?: string | null;

  label: string;
  value?: string | number;
  nameFilter?: string;
  direction?: DIRECTION;
  date?: string | null;
  hidden: boolean;
};

export type TSortItem<KeysSorting> = {
  label: string;
  key: Extract<KeysSorting, string>;
  direction: DIRECTION;
  active: boolean;
};

export type TSort<KeysSorting> = {
  field: KeysSorting;
  direction: DIRECTION;
};

export type TFilterTab<T = ReactNode> = {
  label: T;
  key: string;
  active: boolean;
  tooltip?: string;
  nameFilter?: string;
  icon?: any;
  disabled?: boolean;
};

export type TVariant = {
  value: string;
  date?: string | null;
  placeHolder?: string;
  label: string;
  checked: boolean;
};

export type TConfigFilter = {
  name: string;
  nameForTable?: string;
  label: string;
  typeInput?: 'date';
  variantList: Array<TVariant>;
  render: (props: any) => ReactNode;
  withSearch?: boolean;
  placeholder?: string;
  onGetVariantList?: (search: string | number) => Promise<any>;
  className?: string;
};

export type FuncFiltersTabs = (x: string) => void;
export type FuncFiltersModal = (y: Record<string, Array<TVariant>>) => void;

export type FuncOnFilter = FuncFiltersTabs | FuncFiltersModal;

export type FiltersProps<KeysSorting> = {
  onSearch?: (searchQueries: string[]) => void;
  onSort?: (sortParameters: TSort<KeysSorting> | null) => void;
  onFilter?: FuncOnFilter;
  searchQueries?: string[];
  sortList?: Array<TSortItem<KeysSorting>>;
  tabList?: Array<TFilterTab>;
  rightBlock?: FC | React.ReactNode;
  filtersVariantList?: Array<TConfigFilter>;
  defaultInput?: boolean;
  isDisabled?: boolean;
  rightBlockWrapper?: boolean;
  searchInputClassnames?: string;
  defaultFiltersData?: TDefaultFilters;
  countSearchQuery?: number;
  resetTabs?: boolean;
  showFiltersPopover?: boolean;
  onCustomTagDelete?: (filter: TCustomTag) => void;
  onClearCustomTags?: VoidFunction;
  extraActions?: any;
};

export type TCustomTag = Pick<TChip, 'label' | 'value'>;

export type TDefaultFilters = {
  searchQueries?: Array<string>;
  filter?: Record<string, any>;
  sort?: {
    field: string;
    order: DIRECTION;
  };
  customTags?: Pick<TChip, 'label' | 'value'>[];
};

export type FiltersParams<T> = {
  sort?: TSort<T> | null;
  search?: string[];
  filters?: Record<string, Array<TVariant>>;
  customTag?: TCustomTag;
  isClearAll?: boolean;
};
