import { TPhoneInputValue } from 'src/components/ui/phone-input';

export const phone = (v: TPhoneInputValue) => {
  if (!v || !v.number) {
    return 'Заполните это поле';
  }

  if (v.number.length < 10) {
    return 'Заполните телефон полностью';
  }

  if (v.type !== 'MOBILE' && v.extension === '#') {
    return 'Заполните добавочный номер';
  }

  return true;
};

export const phoneOptional = (v: TPhoneInputValue) => {
  if (!v || !v.number) {
    return true;
  }

  if (v.number.length < 10) {
    return 'Заполните телефон полностью';
  }

  if (v.type !== 'MOBILE' && v.extension === '#') {
    return 'Заполните добавочный номер';
  }

  return true;
};
