import {
  ICustomerCommercialOffer,
  ICustomerCommercialOfferApproved,
  ICustomerCommercialOfferDueDateParams,
  ICustomerCommercialOfferParams,
  ICustomerRejectCommercialOfferParams,
  ICustomerRejectCommercialOfferReasons,
  IRejectCommercialOfferReasonsParams,
} from 'src/models';
import { customerCommercialOffer } from '../../utils';
import { businessRequestApi } from '../business-request';

export const customerCommercialOfferApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerCommercialOffers: builder.query<
      ICustomerCommercialOffer[],
      number
    >({
      query: (id) => ({
        url: `business-requests/api/v1/customer/business-application/${id}/commercial-offer`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: 'getCustomerCommercialOffers', id: arg },
      ],
      transformResponse: (data: ICustomerCommercialOffer[]) =>
        customerCommercialOffer(data),
    }),
    approveCommercialOffer: builder.mutation<
      ICustomerCommercialOfferApproved,
      ICustomerCommercialOfferParams
    >({
      query: ({ commercialOfferId }) => ({
        url: `business-requests/api/v1/customer/commercial-offer/${commercialOfferId}/approval`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getCustomerCommercialOffers',
                id: arg.businessApplicationId,
              },
            ],
    }),
    updateProcessingDateDueAt: builder.mutation<
      null,
      ICustomerCommercialOfferDueDateParams
    >({
      query: ({ commercialOfferId, dueAt }) => ({
        url: `business-requests/api/v1/customer/commercial-offer/${commercialOfferId}/processing-due-date-at`,
        method: 'PATCH',
        body: {
          processingDueDateAt: dueAt,
        },
      }),
      async onQueryStarted(
        { commercialOfferId, dueAt, businessApplicationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            customerCommercialOfferApi.util.updateQueryData(
              'getCustomerCommercialOffers',
              businessApplicationId,
              (data) => {
                data.forEach((item) => {
                  if (item.commercialOfferId === commercialOfferId) {
                    item.processingDueDateAt = dueAt;
                  }
                });
                return data;
              }
            )
          );
        } catch {
          // ignore
        }
      },
    }),
    setCustomerRejectCommercialOffer: builder.mutation<
      void,
      ICustomerRejectCommercialOfferParams
    >({
      query: ({ businessApplicationId, commercialOfferId, ...body }) => ({
        url: `business-requests/api/v1/customer/refusal/commercial-offer-for-customer/${commercialOfferId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'getManagerCustomerCommercialOffers' },
              {
                type: 'getCustomerCommercialOffers',
                id: arg.businessApplicationId,
              },
            ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.invalidateTags([
              { type: 'getManagerStatusHistory' },
            ])
          );

          dispatch(
            businessRequestApi.util.invalidateTags([
              { type: 'getCustomerStatusHistory' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),
    getCustomerRejectCommericalOfferReasons: builder.query<
      ICustomerRejectCommercialOfferReasons,
      IRejectCommercialOfferReasonsParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/admin/commercial-offer-for-customer-refusal-cause-by-customer/search',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetCustomerCommercialOffersQuery,
  useApproveCommercialOfferMutation,
  useUpdateProcessingDateDueAtMutation,
  useSetCustomerRejectCommercialOfferMutation,
  useGetCustomerRejectCommericalOfferReasonsQuery,
} = customerCommercialOfferApi;
