import {
  IndexRouteObject,
  Navigate,
  NonIndexRouteObject,
} from 'react-router-dom';
import {
  LazyCompanyInfo as LazyAdminCompanyInfo,
  LazyCompanyRequisites as LazyAdminCompanyRequisites,
  LazyBankRequisites as LazyAdminBankRequisites,
  LazyCompanyContacts as LazyAdminCompanyContacts,
  LazyCompanyDeliveryAddresses as LazyAdminCompanyDeliveryAddresses,
  LazyCompanyShippingAddresses as LazyAdminCompanyShippingAddress,
  LazyCompanyContract as LazyAdminCompanyContract,
  LazyCompanyTechnicalCapabilities as LazyAdminCompanyTechnicalCapabilities,
  LazyCompanyOkved as LazyAdminCompanyOkved,
  LazyCompanyLawsuits as LazyAdminCompanyLawsuits,
  LazyCompanyFinances as LazyAdminCompanyFinances,
  LazyCompanyFinancialStatements as LazyAdminCompanyFinancialStatements,
  LazyCompanyDebts as LazyAdminCompanyDebts,
} from 'src/components/pages/admin';
import { CompanyTypes } from 'src/models';
import { getAvailableConfigItems } from 'src/configs/admin/company/utils';
import { CompanyTypeCheck } from 'src/components/layout/admin';

export type TRoute =
  | (IndexRouteObject & {
      type?: CompanyTypes;
      title?: string;
      disabled?: boolean;
    })
  | (Omit<NonIndexRouteObject, 'children'> & {
      title?: string;
      type?: CompanyTypes;
      children?: Array<TRoute & { disabled?: boolean }>;
    });

export type TCompanyRouteConfig = Array<TRoute>;

export type TCompanyMenuItem = {
  path: string;
  title: string;
  disabled?: boolean;
  children?:
    | React.ReactNode
    | Array<{
        path: string;
        title: string;
        disabled?: boolean;
      }>;
};

const COMPANY_ROUTES: TCompanyRouteConfig = [
  {
    index: true,
    element: <Navigate to="about" replace />,
  },
  {
    title: 'Компания',
    path: 'about',
    children: [
      {
        title: 'О компании',
        index: true,
        element: <LazyAdminCompanyInfo />,
      },
      {
        title: 'Контакты',
        path: 'contacts',
        element: <LazyAdminCompanyContacts />,
      },
      {
        title: 'Виды деятельности ОКВЭД',
        path: 'okved',
        element: <LazyAdminCompanyOkved />,
      },
      {
        title: 'Финансы',
        path: 'finances',
        element: <LazyAdminCompanyFinances />,
      },
      {
        title: 'Бухгалтерская отчетность',
        path: 'financial-statements',
        element: <LazyAdminCompanyFinancialStatements />,
      },
      // {
      //   title: 'Судебные дела',
      //   path: 'lawsuits',
      //   element: <LazyAdminCompanyLawsuits />,
      // },
      // {
      //   title: 'Долги',
      //   path: 'debts',
      //   element: <LazyAdminCompanyDebts />,
      // },
    ],
  },
  {
    title: 'Реквизиты',
    path: 'requisites',
    children: [
      {
        title: 'Реквизиты компании',
        index: true,
        element: <LazyAdminCompanyRequisites />,
      },
      {
        title: 'Банковские реквизиты',
        path: 'bank',
        element: <LazyAdminBankRequisites />,
      },
      {
        title: 'Адреса отгрузки',
        path: 'shipping',
        element: (
          <CompanyTypeCheck companyType={CompanyTypes.CONTRACTOR}>
            <LazyAdminCompanyShippingAddress />
          </CompanyTypeCheck>
        ),
        type: CompanyTypes.CONTRACTOR,
      },
      {
        title: 'Адреса доставки',
        path: 'delivery',
        element: (
          <CompanyTypeCheck companyType={CompanyTypes.CUSTOMER}>
            <LazyAdminCompanyDeliveryAddresses />
          </CompanyTypeCheck>
        ),
        type: CompanyTypes.CUSTOMER,
      },
    ],
  },
  {
    title: 'Договор',
    path: 'contract',
    children: [
      {
        path: ':contractId',
        element: <LazyAdminCompanyContract />,
      },
    ],
  },
  {
    title: 'Технические возможности',
    path: 'tech-opportunities',
    element: (
      <CompanyTypeCheck companyType={CompanyTypes.CONTRACTOR}>
        <LazyAdminCompanyTechnicalCapabilities />
      </CompanyTypeCheck>
    ),
    type: CompanyTypes.CONTRACTOR,
  },
];

export const {
  routeItems: contractorRouteItems,
  menuItems: contractorMenuItems,
} = getAvailableConfigItems(COMPANY_ROUTES, CompanyTypes.CONTRACTOR);

export const { routeItems: customerRouteItems, menuItems: customerMenuItems } =
  getAvailableConfigItems(COMPANY_ROUTES, CompanyTypes.CUSTOMER);
