import {
  FC,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { filterSpecificRoleTabs } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/tabs/utils';
import { businessRequestMenuItemsByMode } from 'src/configs';
import {
  BusinessRequestMode,
  IManagerBusinessRequest,
  IStatusHistory,
  KamStatusCode,
  TVariantBusinessRequest,
} from 'src/models';
import { AuthService, RolePermissionService } from 'src/services';
import { useAppSelector } from 'src/store';
import { NotificationDot } from 'src/components/ui';
import { TNotification } from 'src/models/notifications';
import { NestedMenuItem } from 'mui-nested-menu';
import classNames from 'classnames';
import { useGetAllCommunicationsChainQuery } from 'src/api/communications';
import { PERMISSIONS } from 'src/constants';
import styles from './tabs.module.scss';

const checkUnreadNotifications = (notifications: TNotification[]): boolean =>
  notifications.length > 0 && notifications.some((elem) => !elem.watched);

type TTabItem = { path: string; title: ReactNode };

export const Tabs: FC<{
  mode: BusinessRequestMode;
  businessRequest: TVariantBusinessRequest;
  statusHistory: IStatusHistory | undefined;
}> = ({ mode, statusHistory, businessRequest }) => {
  const {
    KP,
    AUCTION,
    PAYMENTS,
    REBIDDING,
    REQUEST,
    SHIPMENTS,
    SPECIFICATION,
  } = useAppSelector((state) => state.requestNotifications.notifications);

  const ref = useRef<HTMLDivElement>(null);
  const [visibleTabsItems, setVisibleTabsItems] = useState<TTabItem[]>([]);
  const tabsItemsCount = useRef<number>(0);
  const [hiddenTabsItems, setHiddenTabsItems] = useState<TTabItem[]>([]);

  const canViewCommunications = RolePermissionService.can(
    PERMISSIONS.VIEW_COMMUNICATION_CHAT_WIDGET
  );

  // TODO: Не самое оптимальное решение в табах делать запросы
  const { data: chainData } = useGetAllCommunicationsChainQuery(
    businessRequest?.businessApplicationId?.toString() as string,
    {
      pollingInterval: 2000,
      skip: !businessRequest?.amocrmNumber || !canViewCommunications,
    }
  );

  const getCounterData = (tabPath: string) => {
    if (tabPath === 'communications') {
      return (
        (chainData?.notViewedCount?.emails || 0) +
        (chainData?.notViewedCount?.calls || 0) +
        (chainData?.notViewedCount?.notes || 0) +
        (chainData?.notViewedCount?.messages || 0)
      );
    }

    return undefined;
  };

  const getNotificationSign = (tabPath: string): boolean => {
    if (tabPath === 'customer-request' || tabPath === 'about') {
      return checkUnreadNotifications(REQUEST);
    }
    if (tabPath === 'auction') return checkUnreadNotifications(AUCTION);
    if (tabPath === 'rebidding') return checkUnreadNotifications(REBIDDING);
    if (tabPath === 'commercial-offers') return checkUnreadNotifications(KP);
    if (tabPath === 'specifications') {
      return checkUnreadNotifications(SPECIFICATION);
    }
    if (tabPath === 'payment') return checkUnreadNotifications(PAYMENTS);
    if (tabPath === 'shipping') return checkUnreadNotifications(SHIPMENTS);
    return false;
  };

  const tabsItems = useMemo(() => {
    let tabItems = businessRequestMenuItemsByMode[mode];

    if (
      mode === BusinessRequestMode.SPECIFIC_ROLE &&
      AuthService.isSpecificRole()
    ) {
      const request = businessRequest as IManagerBusinessRequest;
      tabItems = filterSpecificRoleTabs(tabItems, request);
    }

    const isAuctionStatusPresent = statusHistory?.passedStatuses?.find(
      (item) => item.status.code === KamStatusCode.AUCTION_IN_PROGRESS
    );

    const isRebiddingStatusPresent = statusHistory?.passedStatuses?.find(
      (item) => item.status.code === KamStatusCode.REBIDDING_IN_PROGRESS
    );

    if (
      mode === BusinessRequestMode.CONTRACTOR_ALL ||
      (mode === BusinessRequestMode.CONTRACTOR && !isAuctionStatusPresent)
    ) {
      tabItems = tabItems.filter((item) => item.path !== 'auction');
    }

    if (
      mode === BusinessRequestMode.CONTRACTOR_ALL ||
      (mode === BusinessRequestMode.CONTRACTOR && !isRebiddingStatusPresent)
    ) {
      tabItems = tabItems.filter((item) => item.path !== 'rebidding');
    }

    setVisibleTabsItems(tabItems);
    tabsItemsCount.current = tabItems.length;

    return tabItems;
  }, [mode, statusHistory]);

  useLayoutEffect(() => {
    const menuElement = ref.current;

    if (!menuElement) {
      return;
    }

    const menuItemElementList = menuElement.getElementsByClassName('tab-item');
    const menuItemWidthList = Array.from(menuItemElementList).map((item) =>
      item instanceof HTMLElement ? item.offsetWidth : 0
    );

    const dotItemWidth = 36;
    const gapWidth = 12;
    const onResize = () => {
      const visibleItems: TTabItem[] = [];
      const hiddenItems: TTabItem[] = [];
      const menuWidth = menuElement.offsetWidth;
      const lastIndex = menuItemWidthList.length - 1;
      let menuItemsWidth = dotItemWidth;
      let showMoreItem = false;

      for (let i = 0; i <= lastIndex; i++) {
        if (!showMoreItem) {
          menuItemsWidth += menuItemWidthList[i] + gapWidth;

          if (menuItemsWidth > menuWidth) {
            showMoreItem = true;
          }
        }

        if (showMoreItem) {
          hiddenItems.push(tabsItems[i]);
        } else {
          visibleItems.push(tabsItems[i]);
        }
      }

      if (visibleItems.length !== tabsItemsCount.current) {
        setVisibleTabsItems(visibleItems);
        setHiddenTabsItems(hiddenItems);
        tabsItemsCount.current = visibleItems.length;
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', onResize);
  }, [tabsItems]);

  return (
    <div ref={ref} className={styles.container}>
      <ul className={styles.containerTabs}>
        {visibleTabsItems.map((tab, index) => (
          <li key={index} className={classNames(styles.tab, 'tab-item')}>
            <NavLink to={tab.path}>
              <NotificationDot
                isShown={getNotificationSign(tab.path)}
                counter={getCounterData(tab.path)}>
                {tab.title}
              </NotificationDot>
            </NavLink>
          </li>
        ))}
      </ul>
      {hiddenTabsItems.length > 0 && (
        <NestedMenuItem
          label="..."
          parentMenuOpen
          rightIcon={false}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          }}>
          {hiddenTabsItems.map((item) => (
            <NavLink key={item.path} to={item.path} className={styles.moreItem}>
              <NotificationDot isShown={getNotificationSign(item.path)}>
                {item.title}
              </NotificationDot>
            </NavLink>
          ))}
        </NestedMenuItem>
      )}
    </div>
  );
};
