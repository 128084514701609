export enum PERMISSIONS {
  VIEW_CONTRACTOR_LIST = 'VIEW_CONTRACTOR_LIST',
  VIEW_CUSTOMER_LIST = 'VIEW_CUSTOMER_LIST',
  VIEW_OWN_COMPANY = 'VIEW_OWN_COMPANY',

  VIEW_CRM_LINK = 'VIEW_CRM_LINK',
  VIEW_MANAGER_INFO = 'VIEW_MANAGER_INFO',

  VIEW_ADMIN_PANEL = 'VIEW_ADMIN_PANEL',
  VIEW_CLIENT_PANEL = 'VIEW_CLIENT_PANEL',

  VIEW_MANAGER_BUSINESS_REQUEST_LIST = 'VIEW_MANAGER_BUSINESS_REQUEST_LIST',
  VIEW_CUSTOMER_BUSINESS_REQUEST_LIST = 'VIEW_CUSTOMER_BUSINESS_REQUEST_LIST',
  VIEW_CONTRACTOR_BUSINESS_REQUEST_LIST = 'VIEW_CONTRACTOR_BUSINESS_REQUEST_LIST',
  VIEW_MANAGER_CARD = 'VIEW_MANAGER_CARD',

  LOGIN_AS_GUEST = 'LOGIN_AS_GUEST',
  EDIT_BUSINESS_REQUEST = 'EDIT_BUSINESS_REQUEST',

  CREATE_CONTRACTOR = 'CREATE_CONTRACTOR',

  CREATE_REQUEST = 'CREATE_REQUEST',

  CLOSE_REQUEST = 'CLOSE_REQUEST',

  EDIT_COST_LOGISTICS = 'EDIT_COST_LOGISTICS',
  EDIT_SHIPPING_GROUP = 'EDIT_SHIPPING_GROUP',
  EDIT_PAYMENT_GROUP = 'EDIT_PAYMENT_GROUP',
  EDIT_MANAGER_ORDER = 'EDIT_MANAGER_ORDER',

  OPEN_CHAT_BY_COMPANY = 'OPEN_CHAT_BY_COMPANY',
  VIEW_OPEN_CHAT_BUTTON = 'VIEW_OPEN_CHAT_BUTTON',
  SHARE_CONTRACTOR_REQUEST = 'SHARE_CONTRACTOR_REQUEST',

  VIEW_COMMUNICATION_PAGE = 'VIEW_COMMUNICATION_PAGE',
  VIEW_COMMUNICATION_CHAT_WIDGET = 'VIEW_COMMUNICATION_CHAT_WIDGET',

  // APPLICATION
  VIEW_APPLICATION_STATUS_DESCRIPTION_CARD = 'VIEW_APPLICATION_STATUS_DESCRIPTION_CARD',
  VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT = 'VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT',
  VIEW_AND_EDIT_APPLICATION_EMAIL_SENDING_TOGGLE = 'VIEW_AND_EDIT_APPLICATION_EMAIL_SENDING_TOGGLE',
  VIEW_AND_EDIT_THERE_IS_NO_BEST_KP_BUTTON = 'VIEW_AND_EDIT_THERE_IS_NO_BEST_KP_BUTTON',

  EDIT_WHOLE_PAYMENT = 'EDIT_WHOLE_PAYMENT',
  VIEW_REQUEST_NOTIFICATIONS = 'VIEW_REQUEST_NOTIFICATIONS',

  // COMPANY
  VIEW_CONTRACTOR_ROLE = 'VIEW_CONTRACTOR_ROLE',
  EDIT_CONTRACTOR_ROLE = 'EDIT_CONTRACTOR_ROLE',
  VIEW_CUSTOMER_ROLE = 'VIEW_CUSTOMER_ROLE',
  EDIT_CUSTOMER_ROLE = 'EDIT_CUSTOMER_ROLE',
  VIEW_READY_ACCEPT_ORDERS_STATUS = 'VIEW_READY_ACCEPT_ORDERS_STATUS',
  EDIT_READY_ACCEPT_ORDERS_STATUS = 'EDIT_READY_ACCEPT_ORDERS_STATUS',
  VIEW_CONTRACTOR_STATUS = 'VIEW_CONTRACTOR_STATUS',
  EDIT_CONTRACTOR_STATUS = 'EDIT_CONTRACTOR_STATUS',
  EDIT_COMPANY_STATUS = 'EDIT_COMPANY_STATUS',
  VIEW_COMPANY_COMMENT = 'VIEW_COMPANY_COMMENT',
  EDIT_COMPANY_COMMENT = 'EDIT_COMPANY_COMMENT',
  VIEW_FULL_COMPANY_INFO = 'VIEW_FULL_COMPANY_INFO',
  VIEW_FULL_COMPANY_ABOUT_INFO = 'VIEW_FULL_COMPANY_ABOUT_INFO',
  EDIT_COMPANY_CONTACTS = 'EDIT_COMPANY_CONTACTS',
  VIEW_DEBTS_PAGE = 'VIEW_DEBTS_PAGE',
  VIEW_LAWSUITS_PAGE = 'VIEW_LAWSUITS_PAGE',

  // CHANGE_ROLE_TO_CONTRACTOR = 'CHANGE_ROLE_TO_CONTRACTOR',
  // CHANGE_ROLE_TO_CUSTOMER = 'CHANGE_ROLE_TO_CUSTOMER',

  CHANGE_ROLE = 'CHANGE_ROLE',

  VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST = 'VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST',
  VIEW_TEMPLATES = 'VIEW_TEMPLATES',
  VIEW_ALL_COMPANIES = 'VIEW_ALL_COMPANIES',
  VIEW_PAYMENTS = 'VIEW_PAYMENTS',
  VIEW_SHIPMENTS = 'VIEW_SHIPMENTS',
}
