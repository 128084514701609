import {
  IEmailMessage,
  ITelephonyCall,
  INote,
  IMessage,
} from 'src/models/communications';
import { TEvent } from 'src/models/admin';

// TODO: Переделать на static class
export const isEmailItem = (
  item: IEmailMessage | ITelephonyCall | INote | TEvent | IMessage
): item is IEmailMessage =>
  'communicationType' in item && item.communicationType === 'EMAIL';

export const isCallItem = (
  item: IEmailMessage | ITelephonyCall | INote | TEvent | IMessage
): item is ITelephonyCall =>
  'communicationType' in item && item.communicationType === 'TELEPHONY';

export const isNoteItem = (
  item: IEmailMessage | ITelephonyCall | INote | TEvent | IMessage
): item is INote =>
  'communicationType' in item && item.communicationType === 'NOTE';

export const isEventItem = (
  item: IEmailMessage | ITelephonyCall | INote | TEvent | IMessage
): item is TEvent => 'eventType' in item;

export const isMessageItem = (
  item: IEmailMessage | ITelephonyCall | INote | TEvent | IMessage
): item is IMessage =>
  'communicationType' in item && item.communicationType === 'MESSAGE';
