import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { businessRequestApi } from 'src/api/business-request/business-request';
import {
  contractorApi,
  useGetByIdQuery as useGetContractorByIdQuery,
  useLazyGetByIdQuery as useLazyGetContractorByIdQuery,
  useUpdateBankDetailsMutation as useUpdateContractorBankDetailsMutation,
  useUpdateCompanyDetailsMutation as useUpdateContractorCompanyDetailsMutation,
} from 'src/api/contractor';
import {
  customerApi,
  useGetByIdQuery as useGetCustomerByIdQuery,
  useLazyGetByIdQuery as useLazyGetCustomerByIdQuery,
  useUpdateBankDetailsMutation as useUpdateCustomerBankDetailsMutation,
  useUpdateCompanyDetailsMutation as useUpdateCustomerCompanyDetailsMutation,
} from 'src/api/customer';
import { TContactInfo } from 'src/components/pages/company/contacts/types';
import {
  CompanyStatuses,
  CompanyTypes,
  ICompany,
  IKonturFocusData,
  IOrganizationsNamesLite,
} from 'src/models';
import { baseQueryWithReauth } from 'src/store/utils';
import {
  IAddCompanyRoleResponse,
  IOrganizationBankRequisites,
  TAddOrganizationBankRequisites,
} from '../models/company';
import { TMessageRoles } from './communications/models';
import { userApi } from './user';

export const useGetByIdQuery = (type: CompanyTypes) =>
  type === 'CUSTOMER' ? useGetCustomerByIdQuery : useGetContractorByIdQuery;

export const useLazyGetByIdQuery = (type: CompanyTypes) =>
  type === 'CUSTOMER'
    ? useLazyGetCustomerByIdQuery
    : useLazyGetContractorByIdQuery;

export const useUpdateCompanyDetails = (type: CompanyTypes) =>
  type === 'CUSTOMER'
    ? useUpdateCustomerCompanyDetailsMutation
    : useUpdateContractorCompanyDetailsMutation;

export const useUpdateBankDetails = (type: CompanyTypes) =>
  type === 'CUSTOMER'
    ? useUpdateCustomerBankDetailsMutation
    : useUpdateContractorBankDetailsMutation;

interface IUpdateCompanyRequest {
  organizationId: number;
  body: Pick<
    ICompany,
    | 'organizationBankDetails'
    | 'organizationDetails'
    | 'technicalOpportunitiesCategories'
    | 'inn'
    | 'roles'
    | 'comment'
    | 'customerType'
  >;
}

export type TCompanyRequestsCount = {
  activeBusinessApplicationsCount: number;
  totalBusinessApplicationsCount: number;
};

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['BankRequisites'],
  endpoints: (builder) => ({
    changeStatus: builder.mutation<
      void,
      {
        organizationId: number;
        status: CompanyStatuses.ACTIVE | CompanyStatuses.LOCKED;
      }
    >({
      query: (data) => ({
        url: `organizations/api/v1/organization/status`,
        method: 'PATCH',
        body: data,
      }),
      async onQueryStarted(
        { organizationId, status },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                organizationStatus: {
                  code: status,
                },
              })
            )
          );
          dispatch(
            customerApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                organizationStatus: {
                  code: status,
                },
              })
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    addNewCompanyRole: builder.mutation<
      IAddCompanyRoleResponse,
      { organizationId: number; organizationRole: CompanyTypes }
    >({
      query: ({ organizationId, organizationRole }) => ({
        url: `organizations/api/v1/organization/${organizationId}/add-new-role?organizationRole=${organizationRole}`,
        method: 'PATCH',
      }),
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                },
              })
            )
          );
          dispatch(
            customerApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                },
              })
            )
          );
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          /* empty */
        }
      },
    }),

    updateCompany: builder.mutation<ICompany, IUpdateCompanyRequest>({
      query: ({ body, organizationId }) => ({
        url: `organizations/api/v1/organization/${organizationId}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;

          dispatch(
            contractorApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                  ...res.data.organizationDetails,
                },
                comment: res.data.comment,
              })
            )
          );

          dispatch(
            customerApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                  ...res.data.organizationDetails,
                },
                comment: res.data.comment,
              })
            )
          );

          // TODO: Подумать, можно ли сбросить заказчиков, не зная businessRequestId?
          // https://tracker.yandex.ru/DEVINHOUSE-994
          dispatch(businessRequestApi.util?.resetApiState());
        } catch {
          // ignore
        }
      },
    }),

    getContractorNamesLite: builder.query<IOrganizationsNamesLite[], void>({
      query: () => ({
        url: 'organizations/api/v1/organization/contractor/names/lite',
        method: 'POST',
      }),
    }),

    mergeCompanyByInn: builder.mutation<
      { organizationId: number; roles: Array<CompanyTypes> },
      { id: number; inn: string }
    >({
      query: (body) => ({
        url: `organizations/api/v2/organization/${body.id}/merge?inn=${body.inn}`,
        method: 'POST',
      }),
    }),

    getKonturFocusData: builder.query<IKonturFocusData, string>({
      query: (inn: string) => ({
        url: `kontur-focus-adapter/api/v1/organization/${inn}`,
      }),
    }),

    getOrganizationsByIds: builder.query<ICompany[], string[]>({
      queryFn: async (ids, _queryApi, _extraOptions, baseQuery) => {
        const organizationsData = await Promise.all(
          ids.map((id) => baseQuery(`organizations/api/v1/organization/${id}`))
        );

        return {
          data: organizationsData.map(
            (organizationData) => organizationData.data as ICompany
          ),
        };
      },
    }),

    getOrganizationByUserId: builder.query<
      { organizationId: number; organizationName: string },
      number
    >({
      query: (id: number) => ({
        url: `organizations/api/v1/organization/${id}/organization-id`,
      }),
    }),

    getCompanyRequestsCount: builder.query<
      TCompanyRequestsCount,
      { organizationId: number }
    >({
      query: ({ organizationId }) => ({
        url: `business-requests/api/v1/business-application/count-applications/${organizationId}`,
      }),
    }),

    getCompanyHasActiveRequests: builder.query<
      {
        contractorOrganizationRoleHasActiveApplications: boolean;
        customerOrganizationRoleHasActiveApplications: boolean;
      },
      { organizationId: number }
    >({
      query: ({ organizationId }) => ({
        url: `business-requests/api/v1/business-application/${organizationId}/active-applications-exist`,
      }),
    }),

    getActiveOrganizationsByType: builder.query<
      TContactInfo[],
      {
        organizationRole: TMessageRoles;
        searchQuery: string;
      }
    >({
      query: (body) => ({
        url: 'organizations/api/v1/organization/info',
        method: 'POST',
        body,
      }),
    }),

    getOrganizationBankRequisites: builder.query<
      IOrganizationBankRequisites[],
      { organizationId: number }
    >({
      providesTags: ['BankRequisites'],
      query: ({ organizationId }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details`,
      }),
    }),

    addOrganizationBankRequisites: builder.mutation<
      IOrganizationBankRequisites,
      TAddOrganizationBankRequisites
    >({
      invalidatesTags: ['BankRequisites'],
      query: ({ organizationId, organizationRole, ...body }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details?organizationRole=${organizationRole}`,
        method: 'POST',
        body,
      }),
    }),

    deleteOrganizationBankRequisites: builder.mutation<
      void,
      {
        organizationId: number;
        bankDetailsId: number;
        organizationRole: CompanyTypes;
      }
    >({
      invalidatesTags: ['BankRequisites'],
      query: ({ organizationId, bankDetailsId, organizationRole }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details/${bankDetailsId}?organizationRole=${organizationRole}`,
        method: 'DELETE',
      }),
    }),

    updateOrganizationBankRequisites: builder.mutation<
      IOrganizationBankRequisites,
      TAddOrganizationBankRequisites & {
        bankDetailsId: number;
      }
    >({
      invalidatesTags: ['BankRequisites'],
      query: ({
        organizationId,
        bankDetailsId,
        organizationRole,
        ...body
      }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details/${bankDetailsId}?organizationRole=${organizationRole}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});
export const {
  useChangeStatusMutation,
  useGetContractorNamesLiteQuery,
  useMergeCompanyByInnMutation,
  useGetKonturFocusDataQuery,
  useGetOrganizationsByIdsQuery,
  useUpdateCompanyMutation,
  useGetCompanyHasActiveRequestsQuery,
  useLazyGetOrganizationByUserIdQuery,
  useGetCompanyRequestsCountQuery,
  useLazyGetActiveOrganizationsByTypeQuery,
  useAddNewCompanyRoleMutation,
  useGetOrganizationBankRequisitesQuery,
  useAddOrganizationBankRequisitesMutation,
  useDeleteOrganizationBankRequisitesMutation,
  useUpdateOrganizationBankRequisitesMutation,
} = companyApi;
